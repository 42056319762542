import React from 'react'
import { useNavigate } from 'react-router-dom'


const Page_not_found = () => {
    const Navigate = useNavigate();

    const go_lo_login = () => {
        localStorage.clear()
        Navigate('/');
    }
    return (
        <div className='container-fluid pnf_bg'>
            <div className='container con_flex'>
                <p>
                    The page you’re looking <br /> for can’t be found.
                </p>
                <button onClick={go_lo_login}>Go to Login</button>
            </div>
        </div>
    )
}

export default Page_not_found