import React, { useContext } from 'react'
import "./Login.css"
import { Vahan_context } from '../Vahan_context';




const Login = () => {
    const { Login_vahan, setpassword, setusername, loginErr, password, username,Err_login } = useContext(Vahan_context)

    var Head_img = require('../Header/Images/MBFSLogo.png');

    return (
        <div>

            <div className="container-fluid top_head_logos">
                <div className="top_img_dv">
                    <div className="hanburger_sym">
                        <img src={Head_img} alt="MBFS_LOGO" />
                    </div>
                </div>
            </div>
            <div className='container-fluid login_frm'>
                <div className="form_full_align">
                    <h3>Welcome Back</h3>
                    <div className="frm_skelton">
                        <div className="email_dv">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input type="text" onChange={(e) => setusername(e.target.value)} className="form-control" id='username' value={username} />
                            <p className='login_err'>{loginErr.usernameErr}</p>
                        </div>
                        <div className="email_dv">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input type="password" onChange={(e) => setpassword(e.target.value)} className="form-control" id='password' value={password} />
                            <p className='login_err'>{loginErr.userPassword}</p>
                        </div>
                        {loginErr.loginerr === true && (
                            <p style={{ color: 'red', fontWeight: 700 }}>
                                <i className="fa-solid fa-triangle-exclamation" style={{ color: 'rgb(255, 204, 0)', marginRight: 10 }}></i>
                                Invalid User. Please try again!
                            </p>
                        )}
                        <div className="sign_btn_dv">
                            <button className="sign_btn" onClick={Login_vahan} id="btnSignIn">Sign In</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Login
