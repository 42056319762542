import React, { useContext } from 'react'
import Buttons from './Buttons'
import './Button_cnt.css'
import { Link } from 'react-router-dom'
import { Vahan_context } from '../../Vahan_context'




const Button_container = () => {

    const { decryptData,vahan_master_btn,user_master_btn } = useContext(Vahan_context)
    const Usertype = decryptData('Usertype')
    return (
        <div className='button_cnt_in'>
            
            <p>Vahan</p>
            <div className='roww'>
                <div className=' btn_in_prp'>
                    <Link to="/Vahan_master" onClick={vahan_master_btn}>
                        <Buttons
                            innertxt={"Vahan Master"}
                            innerclr={"03a2d6"}
                        />
                    </Link>
                </div>
                { Usertype === "1" && (
                    <div className='btn_in_prp'>
                        <Link to="/User_master" onClick={user_master_btn}>
                            <Buttons
                                innertxt={"User Master"}
                                innerclr={"b3234b"}
                            />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Button_container