
import { Route, Routes } from 'react-router-dom'
import Vahan_master from './Vahan_master'
import User_master from './User_master'


const Table_route = ({setpagenotfound}) => {
    const ErrorRedirect = () => {
        setpagenotfound(true)
    };
    return (
        <div>
            <Routes>
                <Route exact path="/Vahan_master" element={<Vahan_master />} />
                <Route exact path="/User_master" element={<User_master />} />
                <Route path="*" element={<ErrorRedirect />} />
            </Routes>
        </div >
    )
}
export default Table_route