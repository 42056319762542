import React from 'react'

const Unauthorized = () => {
    var mbfs_logo = require('../src/Header/Images/mbfs_logo_mob.png')
    return (
        <div>
            <div className="top_model">
                <div className="model_cent">
                    <img className='Logo_img' src={mbfs_logo} alt="" />
                    <h2>Unauthorized</h2>
                    <p>Your administrator has not assigned you appropriate rights to this page, feature or function.</p>
                </div>
            </div>
        </div>
    )
}

export default Unauthorized