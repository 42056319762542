import React, { useContext, useEffect } from 'react'
import { Vahan_context } from '../../Vahan_context'
import Pagination from './Pagination'

const Vahan_master = () => {
    const { Vahanmasterdata, VahanPaginations, Vahanmasterdatacount, vahanpageNumbers, vahanhandlePageChange, GetVahanMasterdetails,
        Download_Vahan_template, GetBatchNo, addbatchdet, handleFileChange, fileInputRef, handleuploadclick, Getbatchmasterdata,
        batch_upload_submit, vahan_data_download, delete_vahan_fun, setvahandelete, spinner, upload_cancel } = useContext(Vahan_context)

    useEffect(() => {
        GetVahanMasterdetails();
    }, [VahanPaginations])


    useEffect(() => {
        const jwt = sessionStorage.getItem('JWT');
        if (!jwt) {
            const errorButton = document.getElementById('Went_wrong');
            if (errorButton) {
                errorButton.click();
            } else {
                console.error("Element with id 'Went_wrong' not found.");
            }
        }
    }, []);


    const vahan_data_delete = (batch) => {
        document.getElementById('delete_model').click();
        setvahandelete(batch)
    }

    // console.log(Vahanmasterdata)

    return (
        <div className='admin_vahan'>

            <input type="text" hidden id='add_batch_mdl' data-bs-toggle="modal" data-bs-target="#add_batch_hit" />
            <input type="text" hidden id='recordno' data-bs-toggle="modal" data-bs-target="#recordnod" />
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            <input type="text" hidden id='delete_model' data-bs-toggle="modal" data-bs-target="#delete_mdl" />

            {/* <input type="text" hidden id='Went_wrong' data-bs-toggle="modal" data-bs-target="#Went_wrong_model" />

            <div className="modal fade" id="Went_wrong_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered center_to">
                    <div className="modal-content model_content">
                        <div className="inp_btn_hide">
                            <div className="row inp_grid">
                                Something Went Wrong.Please Login Again
                            </div>
                            <div className="text-center btn_submit_dv">
                                <button className="grd_btns" onClick={Err_login}>Ok</button>
                                <button data-bs-dismiss="modal" id='err_back' hidden className="grd_btns grey">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="modal fade" id="add_batch_hit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered center_to">
                    <div className="modal-content model_content">
                        <div className="inp_btn_hide">
                            <div className="row inp_grid">
                                <div className="inp_lb_grid">
                                    <input type="text" hidden data-bs-dismiss="modal" id='upload_cancel_inp' />
                                    <label className="head_lb_txt">Batch No :</label>
                                    <p className="req_txt" id="currentBatchDate">{addbatchdet.batchno}</p>
                                </div>
                                <div className="inp_lb_grid">
                                    <label className="head_lb_txt">Batch Date :</label>
                                    <p className="req_txt" id="currentDate">{addbatchdet.batchdate}</p>
                                </div>
                                <div className="inp_lb_grid">

                                    <button className="grd_btns grey" onClick={handleuploadclick}>
                                        <span>
                                            <i className="fa-solid fa-upload"></i>
                                        </span>
                                        Upload
                                    </button>
                                    <p className="req_txt batch_filename">{addbatchdet.batchfilename}</p>
                                </div>
                                <p className="float_txt" id="invalidText">{addbatchdet.addbatcherrr}</p>
                            </div>
                            <div className="text-center btn_submit_dv">

                                {
                                    addbatchdet && addbatchdet.spinnerforupload === false ? (
                                        <>
                                            <button className="grd_btns" onClick={batch_upload_submit}>Submit</button>
                                            <button data-bs-dismiss="modal" onClick={upload_cancel} id='upload_cancel' className="grd_btns grey">Cancel</button>
                                        </>
                                    ) : (
                                        <div className="spinner-border spiner_bdr" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="delete_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Alert!</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this Batch?
                        </div>
                        <div className="modal-footer">
                            <button className="grd_btns" onClick={delete_vahan_fun}>Ok</button>
                            <button id='delete_dismiss' data-bs-dismiss="modal" className="grd_btns grey">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="recordnod" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Alert!</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Record Not Found
                        </div>
                        <div className="modal-footer">
                            <button data-bs-dismiss="modal" className="grd_btns">Ok</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="modal fade" id="add_batch_hit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered center_to">
                    <div className="modal-content model_content">
                        <div className="inp_btn_hide">
                            <div>
                                Are You Sure Want To delete this record?
                            </div>
                            <div className="text-center btn_submit_dv">
                                <button className="grd_btns">Ok</button>
                                <button data-bs-dismiss="modal" className="grd_btns grey">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="grd_buttons_dv">
                <button onClick={GetBatchNo}>Add Batch</button>
                <button onClick={Download_Vahan_template}>Download Template</button>
            </div>
            <div className='table_sec'>
                <table className='table table_dv'>
                    <thead>
                        <tr>
                            <th>Activity</th>
                            <th>Batch No.</th>
                            <th>Batch Date</th>
                            <th>No. of Request </th>
                            <th>Current Status</th>
                            <th>No. of Request Failed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Vahanmasterdata && Vahanmasterdata.map((vahan, id) => (
                            <tr key={id}>
                                <td className="action_font">
                                    <i className="fa-solid fa-eye" onClick={() => Getbatchmasterdata(vahan.batchid, vahan.status)}></i>
                                    {vahan.status === 2 && (
                                        spinner && spinner === vahan.batchid ? (
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : (
                                            <i onClick={() => vahan_data_download(vahan.batchid)} className="fa-solid fa-download"></i>
                                        )
                                    )
                                    }
                                    {vahan.status === 1 && ""}
                                    {vahan.status !== 1 && vahan.status !== 2 && <i onClick={() => vahan_data_delete(vahan.batchid)} className="fa-solid fa-trash"></i>}
                                </td>
                                <td className={vahan.failedCount !== 0 ? 'vahan_failed' : undefined}>{vahan.batchid}</td>
                                <td className={vahan.failedCount !== 0 ? 'vahan_failed' : undefined}>{vahan.batchDate}</td>
                                <td className={vahan.failedCount !== 0 ? 'vahan_failed' : undefined}>{vahan.noofRequest}</td>
                                <td>{vahan.status === 0 ? (
                                    <span className='back_grnd pend_txt'>Pending</span>
                                ) : vahan.status === 1 ? (
                                    <span className='back_grnd in_pro_txt'>In Progress</span>
                                ) : vahan.status === 2 ? (
                                    <span className='back_grnd com_txt'>Completed</span>
                                ) : (
                                    <span className='back_grnd err_va'>Error</span>
                                )}</td>
                                <td>{vahan.failedCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <div>
                    <p style={{ color: '#000', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{Vahanmasterdatacount}</span></p>
                </div>
                <div>
                    <Pagination
                        pageNumbers={vahanpageNumbers}
                        Paginations={VahanPaginations}
                        handlePageChange={vahanhandlePageChange}
                        LastPage={Vahanmasterdatacount && Math.ceil(Vahanmasterdatacount / 10)}
                    />
                </div>
            </div>
        </div >
    )
}

export default Vahan_master