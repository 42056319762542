import React, { useContext, useEffect } from 'react'
import { Vahan_context } from '../../Vahan_context'
import Pagination from './Pagination'

const User_master = () => {


  const { Usermasterdata, UserpageNumbers, UserPaginations, UserhandlePageChange, Usermasterdatacount, GetUsermasterdata, UserUpdate,
    handle_view_change, UserUpdateerr, Userdetailssubmit, Editusermaster, Userdeltemodel, UserDelete,Err_login,cancelusersumbit } = useContext(Vahan_context)

  useEffect(() => {
    GetUsermasterdata();
  }, [UserPaginations])

  useEffect(() => {
    const jwt = sessionStorage.getItem('JWT');
    if (!jwt) {
        const errorButton = document.getElementById('Went_wrong');
        if (errorButton) {
            errorButton.click();
        } else {
            console.error("Element with id 'Went_wrong' not found.");
        }
    }
}, []);


  return (



    <div>


      <input type="text" hidden id='delete_model' data-bs-toggle="modal" data-bs-target="#delete_hit" />
      <div className="modal fade" id="profile_hit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Add User</h5>
              <button type="button" className="btn-close" onClick={cancelusersumbit} data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Name</label>
              <input className="search_inp_filed id in_tb" onChange={handle_view_change} value={UserUpdate.userName} type="text" name="userName" placeholder="" />
              {UserUpdateerr.userName === true &&
                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
              }
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Email</label>
              <input className="search_inp_filed in_tb" type="text" onChange={handle_view_change} name="emailId" value={UserUpdate.emailId} placeholder="" />
              {UserUpdateerr.emailId === true &&
                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
              }
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Department</label>
              <input className="search_inp_filed in_tb" type="text" onChange={handle_view_change} value={UserUpdate.department} name="department" placeholder="" />
              {UserUpdateerr.department === true &&
                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
              }
              {/* <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Password</label>
              <input className="search_inp_filed in_tb" type="text" onChange={handle_view_change} value={UserUpdate.password} name="password" placeholder="" />
              {UserUpdateerr.password === true &&
                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
              } */}
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">User Status</label>
              <select className="search_inp_filed in_tb" onChange={handle_view_change} value={UserUpdate.userStatus} name='userStatus' id="user_status">
                <option value="2">Active</option>
                <option value="1">InActive</option>
              </select>
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">User Role</label>
              <select className="search_inp_filed in_tb" onChange={handle_view_change} value={UserUpdate.userType} name='userType' id="user_search_in">
                <option value="2">User</option>
                <option value="1">Admin</option>
              </select>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={Userdetailssubmit} className="grn_btn">Save</button>
              <button type="button" data-bs-dismiss="modal" onClick={cancelusersumbit} id='model_cancel' className="btn_delete">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="delete_hit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Alert!</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this record?
            </div>
            <div className="modal-footer">
              <button type="button" data-bs-dismiss="modal" onClick={UserDelete} className="grd_btns">Yes</button>
              <button type="button" data-bs-dismiss="modal" id='model_cancel' className="grd_btns grey">No</button>
            </div>
          </div>
        </div>
      </div>


      <div className="h4_btm_bdr d-flex">
        <h4>USERS</h4>
        <button className="grd_btns" id='add_userdata' data-bs-toggle="modal" data-bs-target="#profile_hit">
          Add
        </button>
      </div>
      <div>
        <table className='table table_dv'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Department</th>
              <th>Status</th>
              <th>User Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Usermasterdata && Usermasterdata.map((user, id) => (
              <tr key={id}>
                <td><p className="initial_round r1">{user.userName.charAt(0).toUpperCase()}</p></td>
                <td>{user.userName}</td>
                <td>{user.emailId}</td>
                <td>{user.department}</td>
                <td>{user.userStatus === "1" ? <span>InActive</span> : <span className="">Active</span>}</td>
                <td>{user.userType === 1 ? <span>Admin</span> : <span>User</span>}</td>
                <td>
                  <button className="grd_btns edit" onClick={() => Editusermaster(user.userName, user.emailId, user.department, user.userStatus, user.userType, user.password, user.identifier)}>Edit</button>
                  <button onClick={() => Userdeltemodel(user.identifier)} className="user_delete">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <div>
          <p style={{ color: '#000', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{Usermasterdatacount}</span></p>
        </div>
        <div>
          <Pagination
            pageNumbers={UserpageNumbers}
            Paginations={UserPaginations}
            handlePageChange={UserhandlePageChange}
            LastPage={Usermasterdatacount && Math.ceil(Usermasterdatacount / 10)}
          />
        </div>
      </div>
    </div>
  )
}

export default User_master