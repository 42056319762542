import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import RedirectLoader from './RedirectLoader';
import axios from 'axios';
import Vahan_batch from '../Main_content/Table_content/Vahan_batch';
import { Vahan_context } from '../Vahan_context';

const SSO_page = () => {
    const [redirecting, setRedirecting] = useState(false);
    const { GetServiceCall } = useContext(Vahan_context)


    const GetRedirectURL = () => {
        GetServiceCall('api/Login/SSOURL')
            .then((res) => {
                console.log(res)
                if (res && res !== undefined && res !== null) {
                    setRedirecting(true);
                    window.location.href = res.ssourl;
                }
            })
            .catch((err) => console.log('Error:', err));
    };

    useEffect(() => {
        localStorage.clear();
        GetRedirectURL();
    }, []);

    return (
        <div>
            {redirecting ? (
                <RedirectLoader />
            ) : (
                <div className='container m-5 p-5 frm_bg'></div>
            )}
        </div>
    )
}

export default SSO_page