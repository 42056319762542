import { useContext, useEffect, useState } from "react";
import "./Fontstyle.css"
import Home from "./Home";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./Login/Login";
import Vahan_batch from "./Main_content/Table_content/Vahan_batch";
import { Vahan_context } from "./Vahan_context";
import SSO_CallBack from "./SSO_Login/SSO_CallBack";
import SSO_page from "./SSO_Login/SSO_page";


function App() {

  const { Err_login } = useContext(Vahan_context)

  return (
    <div className="container-fluid ps-0 pe-0">


      <input type="text" hidden id='Went_wrong' data-bs-toggle="modal" data-bs-target="#Went_wrong_model" />

      <div className="modal fade" id="Went_wrong_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered center_to">
          <div className="modal-content model_content">
            <div className="inp_btn_hide">
              <div className="row inp_grid">
                Your Session Expired. Please Login Again.
              </div>
              <div className="text-center btn_submit_dv">
                <button className="grd_btns" onClick={Err_login}>Ok</button>
                <button data-bs-dismiss="modal" id='err_back' hidden className="grd_btns grey">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Vahan_batch" element={<Vahan_batch />} />
        <Route path="/callback" element={<SSO_CallBack />} />
        <Route path="/" element={<SSO_page />} />
      </Routes>
    </div>
  );
}

export default App;
