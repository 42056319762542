import React from 'react'
import './Main_cnt.css'
import Button_container from './Sub_content/Button_container'
import Table_route from './Table_content/Table_route'

const Main_content = ({ toggle, setpagenotfound }) => {
  return (
    <div className='container-fluid ps-0 pe-0'>
      <div className='bet_tw_cnt_container'>

        <div className={toggle === true ? "sub_head_container" : "sub_head_cnt_m_change"}>
          <Button_container />
        </div>
        <div className='main_table_container'>
          <Table_route setpagenotfound={setpagenotfound} />
        </div>
      </div>
    </div>
  )
}

export default Main_content