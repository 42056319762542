import React, { useContext, useEffect } from 'react'
import { Vahan_context } from '../Vahan_context'
import RedirectLoader from './RedirectLoader';
import { useState } from 'react';

const SSO_CallBack = () => {

    const { GetLoginDetails } = useContext(Vahan_context)
    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        localStorage.clear();
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const randValue = url.searchParams.get('rand') || url.searchParams.get('code');
        const decodedRandValue = randValue ? decodeURIComponent(randValue) : null;
        console.log('Rand value:', decodedRandValue);
        GetLoginDetails(decodedRandValue);
        setRedirecting(true);
    }, []);


    return (
        <div className='container'>
            {redirecting && redirecting ? (
                <RedirectLoader />
            ) : (
                <div className='container m-5 p-5 frm_bg'>Loader...</div>
            )}
        </div>
    )
}

export default SSO_CallBack